import {Delete, get, post, put} from "@/api/axios";

// /web/contract/template/list get
export const list = params => get('/web/contract/template/list', params)

    // /web/contract/template/add post
export const add = params => post('/web/contract/template/add', params)

    // /web/contract/template/view/{id} get
export const view = params => get('/web/contract/template/view/' + params.id, '')

    // /web/contract/template/update put
export const update = params => put('/web/contract/template/update', params)

    // /web/contract/template/delete/{id} delete
export const deleteNew = params => Delete('/web/contract/template/delete/' + params.id, '')