<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10">
          <div class="marginBottom10 marginRight10">
            <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">合同模板创建</el-button>
          </div>
          <el-input size="medium" v-model="searchData.name" clearable :maxlength="100" placeholder="输入名称" class="marginRight10 marginBottom10 width200"/>
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">业务类型 : </span>
            <div class="width200">
              <el-select size="medium" v-model="searchData.relateBusiness" clearable>
                <el-option label="全部" value="" />
                <el-option label="二手房交易" :value="1" />
                <el-option label="房屋租赁" :value="2" />
              </el-select>
            </div>
          </div>
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">合同分类 : </span>
            <div class="width200">
              <el-select size="medium" v-model="searchData.type" clearable>
                <el-option label="全部" value="" />
                <el-option label="交易合同" :value="1" />
                <el-option label="租赁合同" :value="2" />
                <el-option label="居间合同" :value="3" />
                <el-option label="挂牌委托协议" :value="4" />
              </el-select>
            </div>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${form.id ? '编辑' : '创建'}`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, add, view, update, deleteNew } from '@/api/contract/template'
export default {
  name: "index",
  data(){
    return{
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      searchData: {
        name: '',
        relateBusiness: '',
        type: ''
      },
      option: {
        title: '合同模板管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '合同模板名称',
            prop: 'name'
          },
          {
            label: '业务关联',
            prop: 'relateBusiness',
            type: 'radio',
            dicData: [
              { label: '二手房交易', value: 1 },
              { label: '房屋租赁', value: 2 }
            ]
          },
          {
            label: '契约锁合同模板ID',
            prop: 'templateId'
          },
          {
            label: '合同分类',
            prop: 'type',
            type: 'radio',
            dicData: [
              { label: '交易合同', value: 1 },
              { label: '租赁合同', value: 2 },
              { label: '居间合同', value: 3 },
              { label: '挂牌委托协议', value: 4 }
            ]
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '合同模板名称',
            prop: 'name',
            maxlength: 10,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入合同模板名称",
              trigger: "blur"
            }]
          },
          {
            label: '业务关联',
            prop: 'relateBusiness',
            type: 'radio',
            value: 1,
            dicData: [
              { label: '二手房交易', value: 1 },
              { label: '房屋租赁', value: 2 }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "请选择业务关联",
              trigger: "blur"
            }]
          },
          {
            label: '合同分类',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: '交易合同', value: 1 },
              { label: '租赁合同', value: 2 },
              { label: '居间合同', value: 3 },
              { label: '挂牌委托协议', value: 4 }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "请选择合同分类",
              trigger: "change"
            }]
          },
          {
            label: '契约锁合同模板ID',
            prop: 'templateId',
            maxlength: 30,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入契约锁合同模板ID",
              trigger: "blur"
            }]
          },
        ]
      }
    }
  },
  methods: {
    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.searchData = {
        name: '',
        relateBusiness: '',
        type: ''
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 合同模板创建
    handleCreate(){
      // console.log('合同模板创建')
      this.dialogVisible = true;
    },
    // 编辑
    async handleEdit(row){
      // console.log('编辑',row)
      const res = await view({ id: row.id })
      if(res.code === 200){
        this.dialogVisible = true;
        this.form = res.data;
      }else{
        this.$message.error(res.msg)
      }
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此合同模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 新增/编辑规格
    submit(form,done){
      // console.log('新增/编辑规格', form)
      if(form.id){
        update(form).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功')
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm()
            }, 500)
            this.onLoad()
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }else{
        add(form).then(res => {
          if(res.code === 200){
            this.$message.success('新增成功')
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm()
            }, 500)
            this.onLoad()
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹窗
    handleClose(){
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm()
      }, 500)
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
      }
    }
  }
}
</script>

<style scoped>

</style>